exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-apps-tsx": () => import("./../../../src/pages/apps.tsx" /* webpackChunkName: "component---src-pages-apps-tsx" */),
  "component---src-pages-games-floodit-tsx": () => import("./../../../src/pages/games/floodit.tsx" /* webpackChunkName: "component---src-pages-games-floodit-tsx" */),
  "component---src-pages-games-index-tsx": () => import("./../../../src/pages/games/index.tsx" /* webpackChunkName: "component---src-pages-games-index-tsx" */),
  "component---src-pages-games-sherlock-tsx": () => import("./../../../src/pages/games/sherlock.tsx" /* webpackChunkName: "component---src-pages-games-sherlock-tsx" */),
  "component---src-pages-games-three-fiber-tsx": () => import("./../../../src/pages/games/three-fiber.tsx" /* webpackChunkName: "component---src-pages-games-three-fiber-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

